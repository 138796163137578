<template>
  <b-card
      class="code-edit-wrapper"
  >
    <!-- form -->
    <b-form id="codeForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="类型"
              label-for="type"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="type"
                size="sm"
                v-model="code.type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="类型名称"
              label-for="type_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="type_name"
                size="sm"
                v-model="code.type_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="代码"
              label-for="value"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="value"
                size="sm"
                v-model="code.value"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="名称"
              label-for="label"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="label"
                size="sm"
                v-model="code.label"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="外部API代码"
              label-for="api_code"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="api_code"
                size="sm"
                v-model="code.api_code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="排序"
              label-for="position"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="position"
                size="sm"
                v-model="code.position"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="字体颜色"
              label-for="color"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="color"
                v-model="color"
                :options="getCodeOptions('color')"
                class="select-size-sm">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="remark"
                size="sm"
                v-model="code.remark"
            />
          </b-form-group>
        </b-col>
        <!--
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品"
              label-for="product_ids"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="product_ids"
                size="sm"
                v-model="code.test"
                v-b-modal.modal-select-product
                placeholder="点击搜索商品"
            />
          </b-form-group>
        </b-col>
        -->
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>

      <b-table
          responsive="sm"
          :items="items"
      >
      </b-table>

    </b-form>
    <div>
      {{product_ids}}
    </div>
    <!--/ form -->
    <b-modal
        id="modal-select-product"
        ok-only
        ok-title="确认"
        @ok="onSelectProducts"
        cancel-title="取消"
        centered
        size="lg"
        title="选择商品"
    >
      <product-select
          ref="myProductSelect">
      </product-select>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive"
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import codeStore from './codeStore'
import productStore from '../product/productStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, upperCase, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import {codeBasic} from "@/views/table/bs-table/code";
import ProductSelect from '@/views/apps/product/ProductSelect'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BFormCheckbox,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BTable,
    vSelect,
    useToast,
    ProductSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      id: ref(0),
      code: {},
      color: {},
      product_ids: '',
      selected_product: {},
      products: [],
      codeBasic,
      checked: [],
      items: [],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('code')) store.registerModule('code', codeStore)
    if (!store.hasModule('product')) store.registerModule('product', productStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('code')) store.unregisterModule('code')
      if (store.hasModule('product')) store.unregisterModule('product')
    })

    const edit = function() {
      store.dispatch('code/edit', {id: this.id}).then(res => {
        this.code = res.data.data
        this.color = getCode('color', this.code.color)
      })
    }

    const view = function() {
      store.dispatch('code/view', {id: this.id}).then(res => {
        this.code = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      this.code.color = this.color.value
      store.dispatch('code/save', this.code).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-code-list'});
      })
    }


    const onSelectProducts = function(bvModalEvt) {

      let checked = this.$refs.myProductSelect.getChecked();

      this.items = this.items.concat(checked)

      this.code.test = this.items[0].name
      this.selected_product.name = this.items[0].name
    }

    return {
      edit,
      view,
      cancel,
      save,

      onSelectProducts,

      upperCase,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    if (this.id > 0) {
      this.edit()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
